@font-face {
  font-family: "Quicksand";
  src: url("..//fonts/Quicksand-Light.woff2") format("woff2"), url("..//fonts/Quicksand-Light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Quicksand";
  src: url("..//fonts/Quicksand-Medium.woff2") format("woff2"), url("..//fonts/Quicksand-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Quicksand";
  src: url("..//fonts/Quicksand-Regular.woff2") format("woff2"), url("..//fonts/Quicksand-Regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Quicksand";
  src: url("..//fonts/Quicksand-SemiBold.woff2") format("woff2"), url("..//fonts/Quicksand-SemiBold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Quicksand";
  src: url("..//fonts/Quicksand-Bold.woff2") format("woff2"), url("..//fonts/Quicksand-Bold.woff") format("woff");
  font-weight: 700;
}
* {
  box-sizing: border-box;
  margin: 0;
}
*:before,
*:after {
  box-sizing: inherit;
}
@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #0d0f10;
    --secondary-bg-color: #131517;
    --txt-color: #ffffff;
    --bg-color-alert: #353535;
    --color-placeholder: #dedbe6;
    --third-bg-color: rgba(15, 18, 19);
    --promo-bg-color: rgba(19, 21, 23, 0.9);
    --contact-bg-color: #292f33b9;
    --nav-bg-color: #252e35af;
    --main-gradient: linear-gradient(#0c0e0f 0%, #0f1213 40%, #0f1213 74%, #111415 100%);
    --secondary-gradient: linear-gradient(#0c0e0f 0%, #0f1213 40%, #0f1213 74%, #0f1213 100%);
    --secondary-gradient-reversed: linear-gradient(#0f1213, #0f1213 40%, #0f1213 74%, #0c0e0f);
    --promo-gradient: linear, rgba(19, 21, 23, 0.9) 30%, hsla(201, 46%, 73%, 0.3) 70%;
    --shadow-small: rgba(0, 0, 0, 0.2);
    --shadow-portofollio: rgba(73, 95, 106, 0.8);
    --shadow-promo: rgba(22, 69, 94, 0.9);
    --shadow-testimonial-btn: hsla(201, 46%, 73%, 0.7);
  }
  .navLogo img {
    content: url("..//logo_white.svg");
  }
  .logo-footer img {
    content: url("..//logo_white.svg");
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --bg-color: #f3faff;
    --secondary-bg-color: #bfe8ff;
    --third-bg-color: #ebf8ff;
    --nav-bg-color: #e0f4ffdb;
    --promo-bg-color: hsla(201, 100%, 94%, 0.9);
    --contact-bg-color: #e0f4ffb9;
    --main-gradient: linear-gradient(180deg, hsl(205, 100%, 98%) 0%, hsl(204, 100%, 96%) 40%, hsl(203, 100%, 96%) 74%, hsl(201, 100%, 95%) 100%);
    --secondary-gradient: linear-gradient(180deg, #f5fbff, #ebf7ff 40%, #ebf7ff 74%, #ebf8ff);
    --secondary-gradient-reversed: linear-gradient(180deg, #ebf8ff, #ebf7ff 40%, #ebf7ff 74%, #f5fbff);
    --promo-gradient: linear, hsla(201, 100%, 94%, 0.9) 30%, hsla(201, 46%, 73%, 0.8) 70%;
    --shadow-small: rgba(0, 0, 0, 0.2);
    --shadow-portofollio: hsla(201, 15.22%, 54.67%, 0.8);
    --shadow-promo: hsla(201, 46%, 73%, 0.9);
    --shadow-testimonial-btn: rgba(1, 17, 39, 0.2);
    --txt-color: #000000;
    --bg-color-alert: #ffffff;
    --color-placeholder: #616066;
  }
  .contact-img {
    display: none;
  }
  .testimonial-container a h3 img {
    content: url("..//box-arrow-black.svg");
  }
}
html {
  font-size: 16px;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-family: "Quicksand", sans-serif;
  background-color: var(--bg-color);
  color: var(--txt-color);
}
img {
  pointer-events: none;
}
body::-webkit-scrollbar-track {
  position: relative;
  left: -10px;
  background-color: transparent;
  width: 5px;
}
body::-webkit-scrollbar {
  position: relative;
  left: -10px;
  width: 5px;
  height: 30%;
  background-color: #15151500;
}
body::-webkit-scrollbar-thumb {
  position: relative;
  left: -10px;
  border-radius: 10px;
  background-color: #585858aa;
}
body {
  scrollbar-width: none;
}
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
nav {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15%;
  z-index: 4;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: transparent;
  text-align: center;
}
.navMenu {
  display: flex;
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .navMenu {
    display: none;
  }
}
.navLogo {
  align-self: center;
  height: 68%;
  width: auto;
}
.navLogo img {
  height: 100%;
  width: auto;
}
.main {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 60px;
}
.main > * {
  padding-left: 15% !important;
  padding-right: 15% !important;
  width: 100%;
}
.hero-section {
  height: 100dvh;
  background-image: var(--main-gradient);
  margin-top: -60px;
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.hero-section > .cta-Div {
  display: none;
}
.hero-section div:first-child {
  position: relative;
  z-index: 1;
  height: 100%;
  margin-top: -4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 65%;
}
.hero-section div:first-child h1 {
  font-size: 3.4rem;
  letter-spacing: -1px;
  line-height: 3rem;
}
.hero-section div:first-child p {
  margin-top: 1.5rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 1.4rem;
}
.hero-section div:first-child * {
  width: 100%;
}
.hero-section div:first-child .cta-Div {
  z-index: 1;
  margin-top: 7rem;
  margin-bottom: 2rem;
  width: unset;
}
.hero-section div:first-child .cta-Div a {
  width: unset;
}
.hero-section .images {
  position: relative;
  top: 2rem;
  right: -6rem;
  flex-basis: 40%;
  max-width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.hero-section img {
  position: relative;
}
.hero-section .hero-img1 {
  position: absolute;
  top: 0rem;
  right: -15rem;
  width: 100%;
  height: 100dvh;
  scale: 4;
  opacity: 0.4;
  filter: blur(15px);
}
.hero-section .hero-img2 {
  min-height: 65%;
  width: auto;
  aspect-ratio: 3/2;
  rotate: 0deg;
  transform: rotateY(20deg) rotatex(0deg);
  top: -5rem;
  right: -5rem;
}
.cta-Div {
  position: relative;
  display: flex;
}
.cta-Div a {
  margin-right: 10px;
  min-width: 15rem;
  height: 3.2rem;
  background-color: var(--bg-color);
  border: 2px solid #0076fe;
  color: #0076fe;
  font-weight: 500;
  font-size: 1.2rem;
  -webkit-box-shadow: 1px 1px 10px 1px var(--shadow-small);
  box-shadow: 1px 1px 10px 1px var(--shadow-small);
  line-height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
}
.cta-Div a.primaryBtn {
  font-size: 1.2rem !important;
  background-color: #0076fe;
  color: #ffffff;
}
.cta-Div a.primaryBtn:hover {
  background-color: #005ecb;
  border-color: #005ecb;
}
.hero-section .cta-Div {
  justify-self: flex-end;
}
.about-short {
  background-color: var(--third-bg-color);
  text-align: left;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding: 4rem 0 2rem 0;
}
.about-short * {
  padding: 1rem 0 0;
  position: relative;
  z-index: 1;
  max-width: 90%;
}
.about-short h1 {
  font-size: 2.5rem;
  letter-spacing: -1.2px;
  line-height: 2.6rem;
}
.about-short p {
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  line-height: 1.3rem;
}
.redirect-short {
  height: auto;
  padding: 2rem 0 6rem 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.redirect-short .redirect-banner {
  height: auto;
  width: 90%;
  background-color: #ffffff;
  display: flex;
}
.redirect-short .redirect-banner .rdr-left {
  flex-basis: 60%;
  padding: 2rem;
  font-size: 1rem;
  /* align-self: center; */
}
.redirect-short .redirect-banner .rdr-left h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.9rem;
  font-weight: 700;
}
.redirect-short .redirect-banner .rdr-right {
  flex-basis: 40%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f3f3f5;
}
.redirect-short .redirect-banner .rdr-right .cta-Div {
  position: relative;
  bottom: calc(4rem - 100%);
  left: calc(100% - 27rem);
}
.about-section {
  height: auto;
  min-height: calc(25rem + 150px);
  background-image: var(--secondary-gradient);
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.about-section div:first-child {
  align-self: flex-end;
  font-size: 1rem;
  max-width: 55%;
}
.about-section div:first-child h1 {
  font-size: 3rem;
  letter-spacing: -1px;
  line-height: 2.8rem;
}
.about-section div:first-child p {
  font-size: 1.1rem;
  letter-spacing: 1.2px;
  line-height: 1.3rem;
}
.about-section div:first-child > * {
  max-width: 800px;
  position: relative;
  margin-top: 1.5rem;
  z-index: 1;
}
.about-section img {
  position: absolute;
  z-index: 1;
  top: 60px;
  left: calc(15%);
  width: 480px;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.about-section .about-img2 {
  z-index: 0;
  top: -25rem;
  left: -50%;
  width: 100%;
  height: 50%;
  scale: 3;
  opacity: 0.33;
  filter: blur(15px);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.cta-about {
  z-index: 1;
  margin-top: 2rem;
  display: flex;
  align-self: flex-end;
}
.cta-about a {
  width: 14rem;
  height: 3rem;
  font-weight: 500;
  font-size: 1.05rem;
  -webkit-box-shadow: 1px 1px 10px 1px var(--shadow-small);
  box-shadow: 1px 1px 10px 1px var(--shadow-small);
  text-align: center;
  line-height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.our-team {
  background-image: var(--secondary-gradient-reversed);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-bottom: 4rem;
  padding-top: 1rem;
}
.our-team > * {
  margin: 1.8rem;
}
.our-team .headline {
  margin-right: 0;
  margin-left: 0;
  max-width: 90%;
}
.our-team .headline h1 {
  font-size: 2.5rem;
  letter-spacing: -1.2px;
  line-height: 2.6rem;
}
.our-team .headline p {
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  line-height: 1.3rem;
  margin-top: 1rem;
}
.our-team .team {
  display: flex;
  justify-content: space-around;
  width: 90%;
}
.our-team .team-member {
  flex-basis: 22%;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}
.our-team .team-member h3 {
  margin-top: 8px;
  font-size: 1.1rem;
  letter-spacing: -0.5px;
  line-height: 1.3rem;
  width: 100%;
}
.our-team .team-member p {
  font-size: 1rem;
}
.our-team .team-member > * {
  margin-top: 0.4rem;
}
.our-team .title {
  font-style: oblique;
  font-size: 0.9rem;
  letter-spacing: -0.5px;
  line-height: 1.1rem;
  height: 2.2rem;
}
.our-team .profilePhoto {
  width: 90%;
  max-width: 300px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
}
.our-team .anchors {
  width: 100%;
  min-width: 145px;
  margin-top: auto;
  display: flex;
}
.our-team .anchors img {
  margin-right: 1rem;
  margin-top: 1rem;
  height: 25px;
  width: 25px;
}
.our-team .anchors a:last-child img {
  margin-right: 0px;
}
.portofolio-section {
  position: relative;
  z-index: 1;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.portofolio-section .headline {
  max-width: 800px;
  margin-bottom: 2rem;
  color: var(--txt-color);
}
.portofolio-section .headline h1 {
  font-size: 3rem;
  letter-spacing: -1.8px;
  line-height: 2.7rem;
}
.portofolio-section .headline p {
  margin-top: 1rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 1.3rem;
}
.portofolio-info {
  position: relative;
  top: 2rem;
  background: var(--promo-bg-color);
  background: radial-gradient(var(--promo-gradient));
  background: -moz-radial-gradient(var(--promo-gradient));
  background: -webkit-radial-gradient(var(--promo-gradient));
  box-shadow: 0 3px 20px 5px var(--shadow-portofollio);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
  min-width: 1200px;
  min-height: 45rem;
  display: flex;
  padding: 2rem;
  border-radius: 40px;
  justify-content: space-between;
}
.info-section {
  flex-basis: 70%;
  padding: 1rem 3rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 1rem;
}
.info-section h1:first-child {
  margin-top: 2rem;
  font-size: 2.5rem;
  letter-spacing: -1.2px;
  line-height: 2.6rem;
}
.info-section h2 {
  font-size: 1.8rem;
  letter-spacing: -1.2px;
  line-height: 2rem;
}
.info-section p,
.info-section span {
  font-size: 1.1rem;
  letter-spacing: 1.2px;
  line-height: 1.3rem;
}
.info-section > * {
  margin-bottom: 1.2rem;
  max-width: 55rem;
}
.info-section .cta-Div {
  margin: auto auto 1rem auto;
}
.info-section .cta-Div a {
  width: 14rem;
  height: 4rem;
  margin-right: 0px;
}
.info-section .cta-Div a:first-child {
  margin-right: 2rem;
}
.phone-section {
  flex-basis: 40%;
  display: flex;
}
.phone-out {
  position: relative;
  height: 95%;
  aspect-ratio: 9/19;
  background-color: #616163;
  box-shadow: 0 4px 15px 0 hsla(196, 14%, 15%, 0.9);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
  margin: auto auto;
}
.phone-in {
  position: absolute;
  width: 98%;
  height: 99%;
  background-color: #000000;
  border-radius: 47px;
  top: 0.5%;
  left: 1%;
}
.phone-in button {
  position: absolute;
  width: 15%;
  height: calc(100% / 19 * 9 * 0.15);
  left: calc(50% - 7.5%);
  bottom: 2%;
  background-color: #000000;
  border-radius: 50%;
  border: 3px solid #4d4c51;
}
.power-btn {
  position: absolute;
  background-color: #616163;
  height: 7%;
  width: 6px;
  left: 99.5%;
  top: 20%;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.volume-btns {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 16%;
  left: -4.5px;
  top: 22%;
}
.volume-btns div {
  height: 45%;
  width: 6px;
  background-color: #616163;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
#screen {
  position: absolute;
  width: 95%;
  height: 80%;
  top: 9%;
  left: 2.5%;
  background-color: #ffffff;
  overflow: hidden;
}
.upper-screen {
  position: absolute;
  height: 16px;
  width: 130px;
  top: 4%;
  left: calc(50% - 80px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.camera {
  height: 14px;
  width: 14px;
  background-color: #2e2e2e;
  border-radius: 14px;
  display: flex;
}
.camera img {
  height: 100%;
  aspect-ratio: 1/1;
  opacity: 0.3;
  overflow: hidden;
  object-fit: cover;
}
.ear-piece {
  background-color: #2e2e2e;
  height: 8px;
  width: 100px;
  border-radius: 6px;
}
iframe {
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2px);
  border: none;
  z-index: 0;
  width: calc(100% + 3px);
  /* display: block; */
  transition: transform 0.3s ease-in-out;
}
.zoomIn {
  animation: zoomInAnimation 0.2s ease-in-out;
}
.zoomOut {
  animation: zoomOutAnimation 0.2s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes zoomInAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes zoomOutAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
#main-screen {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #4da0ff;
  background-image: url("..//phone_bg_gradient.png");
  background-size: cover;
  background-position: center;
}
#main-screen > img {
  display: none;
  position: absolute;
  width: 80%;
  height: auto;
  left: -20%;
  top: 60%;
  opacity: 0.8;
}
#main-screen #time {
  position: absolute;
  width: 40px;
  left: calc(50% - 20px);
  top: 4px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
}
.signal {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 18px;
  height: 10px;
  top: 5px;
  left: 12px;
}
.signal div {
  display: inline-block;
  background-color: #ffffff;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  width: 2.5px;
}
.battery {
  position: absolute;
  top: 5px;
  left: 89%;
  width: 20px;
  height: 11px;
  background-color: transparent;
  border: 1.5px solid #ffffff;
  border-radius: 3px;
}
.battery .bat-in {
  margin: 1px auto 1px 1px;
  width: 65%;
  height: 7px;
  background-color: #ffffff;
  border-radius: 1px;
}
.battery .bat-out {
  position: absolute;
  top: calc(50% - 4px);
  left: 100%;
  margin-top: 4%;
  width: 3px;
  height: 6px;
  background-color: #ffffff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.our-apps {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: calc(100% - 30px - 60px - 1.5% - 10px);
  margin-top: 25px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  /* justify-content: flex-start;
  align-items: baseline; */
  padding: 3%;
}
.our-apps div {
  flex-basis: 25%;
  height: 75px;
  line-height: 26px;
  font-size: 12.5px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  cursor: pointer !important;
}
.our-apps div img {
  width: 80%;
  align-self: center;
  border-radius: 12px;
  margin-bottom: 3px;
}
.our-apps div span {
  color: #fff;
  font-size: 0.68rem;
  line-height: 0.8rem;
  letter-spacing: 0.5px;
  font-weight: 500;
}
.central-apps {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: calc(100% - 30px - 75px);
  margin-top: calc(25px + 75px);
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-content: flex-start;
  /* justify-content: flex-start;
  align-items: baseline; */
  padding: 3%;
}
.central-apps div,
.central-apps a {
  flex-basis: 25%;
  height: 55px;
  line-height: 26px;
  font-size: 12.5px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.central-apps div img,
.central-apps a img {
  width: 75%;
  height: auto;
  aspect-ratio: 1/1;
  align-self: center;
  border-radius: 10px;
}
.main-apps {
  position: absolute;
  width: 95%;
  height: 65px;
  top: calc(100% - 65px - 1.5% - 5px);
  left: 2.5%;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
button#home-btn {
  box-shadow: 0 4px 26px 0 rgba(85, 88, 116, 0.3);
  cursor: pointer;
}
button#home-btn:active {
  scale: 0.93;
}
.badge {
  border: none !important;
  background-color: transparent !important;
  color: transparent !important;
  border-radius: 8px;
  overflow: hidden;
}
.badge img {
  width: 101%;
  height: auto;
}
.main-help {
  min-height: calc(100dvh);
  position: relative;
}
.main-help > * {
  position: relative;
  z-index: 2;
}
.main-contact {
  min-height: calc(100dvh);
}
.help-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.4;
  top: -50%;
  right: -10%;
  width: 100%;
  scale: 1.1;
  filter: blur(3px);
  rotate: 90deg;
  height: auto;
}
.porto-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.4;
  top: -50%;
  right: -10%;
  width: 100%;
  scale: 1.1;
  filter: blur(3px);
  rotate: 120deg;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.artic1-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.25;
  top: -30%;
  left: -30%;
  width: 100%;
  scale: 1.1;
  filter: blur(4px);
  rotate: 60deg;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.artic2-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.3;
  top: 30%;
  right: -30%;
  width: 100%;
  scale: 1.1;
  filter: blur(3px);
  rotate: 100deg;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.articDet1-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.25;
  top: -10rem;
  left: -35%;
  width: 120%;
  scale: 1.1;
  filter: blur(4px);
  rotate: 60deg;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.articDet2-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.3;
  top: 30%;
  right: -30%;
  width: 120%;
  scale: 1.1;
  filter: blur(3px);
  rotate: 100deg;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.articDet3-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.3;
  bottom: 0;
  left: -30%;
  width: 120%;
  scale: 1.1;
  filter: blur(3px);
  rotate: 100deg;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.contact-img {
  position: absolute;
  z-index: 0;
  padding: 0px !important;
  opacity: 0.4;
  top: -50%;
  right: -10%;
  width: 100%;
  scale: 1.1;
  filter: blur(3px);
  rotate: 120deg;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.faq-section {
  background-image: linear-gradient(180deg, hsl(205, 100%, 98%) 0%, hsl(204, 100%, 96%) 30%, hsl(203, 100%, 95%) 64%, hsl(201, 100%, 94%) 100%);
  display: flex;
  justify-content: center;
  height: 32rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: none;
}
.ilustration {
  display: none;
  position: relative;
  top: -26rem;
  height: 44rem;
  left: -1rem;
  width: 20rem;
  min-width: 20rem;
  background-color: #006684;
  margin: 2rem;
  border-radius: 20px;
}
.ilustration .head-message {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.6rem;
  justify-content: space-around;
  width: 14rem;
  height: 4rem;
  background-color: var(--bg-color);
  top: 3rem;
  left: -4rem;
}
.ilustration .messages {
  position: relative;
  top: 5rem;
  display: flex;
  flex-direction: column;
  margin: 0px 1.4rem;
}
.ilustration .mess {
  align-self: flex-end;
  margin-bottom: 1rem;
  text-align: right;
}
.ilustration .mess > * {
  margin-bottom: 0.2rem;
}
.ilustration .mess p {
  font-size: 0.8rem;
  width: 10.5rem;
  padding: 0.7rem;
  background-color: var(--bg-color);
  border-radius: 15px;
}
.ilustration .mess span {
  font-size: 0.7rem;
  color: #fff;
  margin: 0 3px;
}
.ilustration .mess p {
  margin-bottom: -4px;
}
.ilustration .mess:nth-child(2),
.ilustration .mess:nth-child(4) {
  align-self: flex-start;
  text-align: left;
}
.ilustration .mess:nth-child(2) :nth-child(even),
.ilustration .mess:nth-child(4) :nth-child(even) {
  margin-bottom: -4px;
}
.ilustration .chat-icon {
  position: relative;
  width: 70px;
  top: 0rem;
  left: 88.5%;
  aspect-ratio: 1/1;
}
.faq-questions {
  display: none;
  padding: 2rem 0px;
  width: 70%;
}
.faq-questions h1 {
  margin-bottom: 1.5rem;
}
.faq-questions .question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  border-bottom: 2px solid #00658436;
  font-size: 1rem;
}
.faq-questions .question div {
  width: calc(100% - 35px - 1rem);
}
.faq-questions .question h4 {
  font-weight: 400;
  font-size: 1rem;
}
.faq-questions .question:last-child {
  border-bottom: none;
}
.faq-questions .question img {
  border: 2px solid #0077fea1;
  padding: 5px;
  height: 35px;
  aspect-ratio: 1/1;
  align-self: center;
  rotate: -90deg;
}
.faq-questions .answer {
  display: none;
}
.schedule {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding-top: 2rem;
}
.schedule-button {
  order: -1;
  width: 50%;
  padding-right: 3rem;
  margin-top: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.schedule-button > * {
  margin-bottom: 1rem;
}
.schedule-button h1 {
  font-size: 2.5rem;
  letter-spacing: -1.2px;
  line-height: 2.6rem;
}
.schedule-button p {
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  line-height: 1.3rem;
}
.head-help {
  margin-top: 2rem;
  font-size: 3rem;
  letter-spacing: -1.4px;
  line-height: 2.8rem;
}
.calendlyButton {
  margin-top: 5rem;
  align-self: flex-end;
  width: 100%;
  max-width: 320px;
  background-color: transparent;
  padding: 25px 120px;
  position: relative;
  letter-spacing: 1px;
  cursor: pointer;
}
.calendlyButton .btn__white-circle,
.calendlyButton .btn__circle {
  position: absolute;
}
.calendlyButton .btn__circle {
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 10px;
  width: 100%;
  background-color: #0076fe;
  transition: 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendlyButton .btn__circle span {
  font-size: 1.2rem;
  font-weight: 600;
  flex-basis: 80%;
}
.calendlyButton .btn__white-circle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  box-shadow: 0 0 1px 1px #0076fe;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  display: none;
}
.calendlyButton .btn__white-circle span {
  color: #0076fe;
  font-size: 1rem;
  font-weight: 600;
  flex-basis: 80%;
}
.calendlyButton svg {
  width: auto;
  height: 50%;
  aspect-ratio: 1/1;
}
.calendlyButton :hover {
  background-color: #005ecb;
  border-color: #005ecb;
}
.promo {
  align-self: flex-start;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: var(--promo-bg-color);
  background: radial-gradient(var(--promo-gradient));
  background: -moz-radial-gradient(var(--promo-gradient));
  background: -webkit-radial-gradient(var(--promo-gradient));
  box-shadow: 0 8px 30px 0 var(--shadow-promo);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  margin: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: auto;
  min-width: 480px;
  min-height: 420px;
}
.promo .headline {
  margin-top: -7rem;
  align-self: flex-end;
  text-align: right;
  max-width: 180px;
}
.promo .headline h2 {
  font-size: 2rem;
}
.promo .headline p {
  margin-top: 0.2rem;
}
.talk-points {
  margin-top: 1rem;
  font-size: 1rem;
}
.talk-points ul {
  list-style-type: none;
  padding-left: 0px;
}
.talk-points ul li {
  font-size: 0.9rem;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}
.talk-points ul li img {
  margin-right: 10px;
}
.price {
  position: relative;
  top: 0rem;
}
.price h2 {
  font-size: 2.3rem;
}
.price .line {
  width: 55px;
  border-bottom: 2.5px solid var(--txt-color);
  rotate: -41.5deg;
  margin-left: 30px;
}
.price span {
  display: block;
  margin-left: 60px;
  width: 60px;
  line-height: 1rem;
  height: 3rem;
  overflow: hidden;
}
.footer {
  position: relative;
  z-index: 1;
  height: auto;
  width: 100%;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  padding: 0 15%;
  background-color: var(--secondary-bg-color);
  color: var(--txt-color);
}
.footer div {
  padding: 0.2rem 0rem 0.2rem 0;
}
.footer h3 {
  margin-bottom: 0.3rem;
}
.logo-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 80%;
}
.logo-footer img {
  height: 60px;
  width: auto;
  padding: 10px;
  padding-left: 0px;
}
.logo-footer div:first-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-style: oblique;
  margin-bottom: 8px;
}
.terms-info {
  display: flex;
  align-items: baseline;
  font-size: 0.7rem;
}
.terms-info > span {
  margin: 0 5px;
}
.terms-info > a {
  color: var(--txt-color);
  text-decoration: none;
}
.links-footer {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.links-footer > a {
  text-align: center;
  height: 1.2rem;
  line-height: 1rem;
  font-size: 0.9rem;
  padding: 0px;
  margin-top: 2px;
  color: var(--txt-color);
}
.contact-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.contact-footer > * {
  margin-bottom: 2px;
}
.contact-footer a {
  color: var(--txt-color);
  text-decoration: none;
}
.flw-img {
  margin: 0px;
  height: 25px !important;
  margin-bottom: 6px !important;
  padding-right: 0px;
}
.flw-img img {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}
.calendly-overlay .calendly-popup {
  max-height: none !important;
}
.headline-contact {
  margin-top: 2rem;
}
.headline-contact h1 {
  font-size: 3rem;
  letter-spacing: -1.4px;
  line-height: 2.8rem;
}
.headline-contact p {
  margin-top: 1rem;
  font-size: 1.1rem;
  letter-spacing: 0.8px;
  line-height: 1.3rem;
}
.contact {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  margin-top: 1rem;
}
.contact-info {
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  margin: 2rem 2rem 2rem 0px;
}
.contact-info a {
  display: flex;
  text-decoration: none;
  align-items: center;
  color: var(--txt-color);
  width: 90%;
  min-width: 280px;
  background-color: var(--contact-bg-color);
  padding-left: 1rem;
}
.contact-info a * {
  margin-right: 1rem;
}
.contact-info a img {
  width: 25px;
  height: auto;
}
.contact-info .info-contc a:nth-child(2) {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.contact-info .info-contc > a:nth-child(3) {
  border-top: 2px solid #565e6d38;
  border-bottom: 2px solid #565e6d38;
}
.contact-info .info-contc > a:nth-child(4) {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 1rem;
}
.contact-info .social-info a:nth-child(2) {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.contact-info .social-info > a {
  display: flex;
  padding: 5px;
  padding-left: 1rem;
  width: 90%;
  min-width: 280px;
  border-bottom: 2px solid #565e6d38;
}
.contact-info .social-info > a img {
  width: 30px;
  height: auto;
}
.contact-info .social-info > a .playStoreLogo {
  scale: 1;
}
.contact-info .social-info > a :last-child {
  scale: 0.8;
  margin-left: auto;
}
.contact-info .social-info > a:nth-child(5) {
  border-bottom: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 1rem;
}
.contact-info h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}
.contact-info .hidden {
  display: none;
}
.contact-info * {
  padding: 0.3rem 0px;
}
.contact-info #map {
  position: relative;
  height: 300px !important;
  width: 90% !important;
}
.contact-info .dots {
  position: absolute;
  width: 300px;
  z-index: -1;
  height: auto;
  top: 25rem;
  opacity: 0.8;
  left: 29%;
}
.contact-form {
  min-width: 520px;
  flex-basis: 42%;
  display: flex;
  flex-direction: column;
  margin: 2rem 0rem 2rem 0px;
}
.pearson-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pearson-info div {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
}
#company-name,
#help-text {
  margin-bottom: 1.2rem;
}
textarea {
  min-height: 300px;
  max-height: 450px;
}
label {
  font-weight: 600;
  margin-bottom: 3px;
}
input,
textarea {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  border: none;
  color: var(--txt-color);
  background-color: var(--contact-bg-color);
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.8rem;
  resize: vertical;
  font-size: 1rem;
}
input:placeholder-shown,
textarea:placeholder-shown {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-style: oblique;
  font-size: 1rem;
}
@media (prefers-color-scheme: dark) {
  input:placeholder-shown,
  textarea:placeholder-shown {
    color: #ffffff;
  }
}
button {
  font-family: "Quicksand", sans-serif;
  border: none;
  padding: 0.9rem;
  color: #fff;
  background-color: #0076fe;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}
button :hover {
  background-color: #005ecb;
  border-color: #005ecb;
}
button:active {
  scale: 0.95;
}
#submit-btn {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
#submit-btn svg {
  position: absolute;
  height: 2.6rem;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
#submit-btn .text-sent,
#submit-btn .text-error {
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
#submit-btn .text-submit {
  position: absolute;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
#submit-btn:hover {
  background-color: #005ecb;
  border-color: #005ecb;
}
.spinner_wait {
  position: relative;
  -webkit-animation: rotator 2s linear infinite;
  animation: rotator 2s linear infinite;
}
@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
.path_wait {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 2s ease-in-out infinite, colors 10s ease-in-out infinite;
  animation: dash 2s ease-in-out infinite, colors 10s ease-in-out infinite;
}
@-webkit-keyframes colors {
  0% {
    stroke: white;
  }
  100% {
    stroke: white;
  }
}
@keyframes colors {
  0% {
    stroke: white;
  }
  100% {
    stroke: white;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
.testimonials {
  background-color: var(--third-bg-color);
  height: auto;
  min-height: 620px;
  padding: 3rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.testimonials h1 {
  font-size: 2.5rem;
  padding: 1rem 0;
  margin-bottom: 2rem;
  letter-spacing: -1.8px;
  line-height: 2.6rem;
}
.wrapper {
  align-self: center;
  position: relative;
  width: 80%;
  border-radius: 1.5rem;
  background-color: var(--third-bg-color);
  box-shadow: 0 8px 20px 0 hsla(201, 46%, 73%, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
}
.wrapper .content {
  height: auto !important;
}
.testimonial-container {
  opacity: 1;
  width: 85%;
  height: 100%;
  position: relative;
  margin: auto;
  padding: 1.8em 1.2em;
}
.wrapper button {
  font-size: 2.5rem;
  line-height: 1.2rem;
  height: 4rem;
  width: 4rem;
  background-color: var(--bg-color);
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border: none;
  color: #0a69ed;
  box-shadow: 0 0 0.5rem var(--shadow-testimonial-btn);
  cursor: pointer;
  border-radius: 50%;
}
button#next {
  z-index: 1;
  right: -2rem;
  display: flex;
  align-self: center;
  justify-content: center;
}
button#next img {
  margin-left: 5px;
  height: 100%;
  width: auto;
  rotate: -90deg;
}
button#prev {
  left: -2rem;
  display: flex;
  align-self: center;
  justify-content: center;
}
button#prev img {
  margin-right: 5px;
  height: 100%;
  width: auto;
  rotate: 90deg;
}
.testimonial-container p {
  color: var(--txt-color);
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  line-height: 1.3rem;
}
.testimonial-container img {
  display: block;
  margin: 1.8em auto 1.25em auto;
  border-radius: 50%;
  width: 4.4em;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.testimonial-container a {
  text-decoration: none;
}
.testimonial-container a img {
  display: inline;
  width: 0.8rem;
  height: auto;
  margin: 0 0 0.3rem 0.5rem;
  border-radius: 0;
}
.testimonial-container h3 {
  color: var(--txt-color);
  font-size: 1.3rem;
  text-align: center;
}
.testimonial-container h6 {
  color: #8f95a8;
  font-size: 1.1rem;
  letter-spacing: 0.03em;
  font-weight: 400;
  text-align: center;
}
.terms {
  padding-top: 40px;
  padding-bottom: 60px;
}
.bounce {
  outline: 0;
  border: 2px solid #ff917d;
  animation-name: bounce;
  animation-duration: 0.6s;
  animation-delay: 0.2s;
}
@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(3px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-3px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
#body-blackout {
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: none;
}
.popup-modal {
  background-color: var(--bg-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  opacity: 0;
  pointer-events: none;
  transition: all 500ms ease-in-out;
  z-index: 1011;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 300px;
}
.popup-modal p {
  font-size: 1.1rem;
  padding: 10px 0px 15px 0px;
}
.popup-modal h3 {
  font-size: 2rem;
}
.error {
  margin-top: 1rem;
  cursor: pointer;
  height: 40px;
  width: 50%;
  padding: 0;
  text-align: center;
  font-size: 1.2rem;
  border-radius: 8px;
  line-height: 1rem;
  align-self: flex-end;
}
.headline-articles {
  margin-top: 2rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
.headline-articles h1 {
  font-size: 3rem;
  letter-spacing: -1.4px;
  line-height: 2.8rem;
}
.headline-articles p {
  max-width: 80%;
  margin-top: 1rem;
  font-size: 1.1rem;
  letter-spacing: 0.8px;
  line-height: 1.3rem;
}
.headline-article {
  width: 58%;
  padding: 0 1rem;
  margin-top: 2rem;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}
.headline-article h1 {
  font-size: 3rem;
  letter-spacing: -1.4px;
  line-height: 2.8rem;
}
.headline-article .topics {
  margin-top: 1rem;
  font-size: 1.1rem;
  letter-spacing: 0.8px;
  line-height: 1.3rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.article-list-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 90dvh;
  padding-bottom: 3rem;
  position: relative;
  z-index: 1;
}
.article-list-container .article-list-options {
  order: 1;
  width: 25%;
  height: calc(90vh - 3rem);
  padding: 1rem 1.5rem 2rem 1.5rem;
  margin-top: 1rem;
  border-left: 1px solid var(--shadow-portofollio);
  display: flex;
  flex-direction: column;
  align-self: start;
  margin-right: 7.5%;
}
.article-list-container .article-list-options h3 {
  margin-bottom: 0.5rem;
}
.article-list-container .article-list-options .topics {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.article-list-container main {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7.5%;
  padding: 2rem;
  gap: 1rem;
}
.article-list-container main .article {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--shadow-portofollio);
  cursor: pointer;
}
.article-list-container main .article .article-author {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  gap: 0.25rem;
}
.article-list-container main .article .article-author img {
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.article-list-container main .article .article-author span {
  font-size: 1rem;
  font-weight: 700;
}
.article-list-container main .article .article-author .article-date {
  font-weight: 400;
  font-size: 0.8rem;
}
.article-list-container main .article .article-section {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 0.25rem;
}
.article-list-container main .article .article-section .article-content {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}
.article-list-container main .article .article-section .article-content p {
  height: 4rem;
  white-space: normal;
  overflow: hidden;
  line-height: 1rem;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.article-list-container main .article .article-section .article-thumbnail {
  width: 25%;
}
.article-list-container main .article .article-section .article-thumbnail img {
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
}
.article-list-container main .article .article-footer {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}
.article-list-container main .article .article-footer .article-read-time {
  font-weight: 600;
  font-size: 0.75rem;
}
.article-list-container main .article .article-footer span {
  font-size: 1rem;
  font-weight: 700;
}
.article-list-container main .article .article-footer .articleTag {
  font-size: 0.8rem;
  margin-right: 0.25rem;
  font-weight: 500;
}
.article-list-container main .article .article-footer .article-tags {
  display: flex;
}
.article-list-container main .article:first-of-type {
  padding-top: 0px;
  border-top: none;
}
.articleTag {
  height: min-content;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: var(--contact-bg-color);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.article-container {
  margin-top: 1rem;
  width: 100%;
  min-height: 90dvh;
  padding-bottom: 3rem;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
}
.article-container .article {
  width: 58%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.article-container .article .article-author {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-top: 1px solid var(--shadow-portofollio);
  border-bottom: 1px solid var(--shadow-portofollio);
}
.article-container .article .article-author .author {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.article-container .article .article-author .author img {
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.article-container .article .article-author .author span {
  font-size: 1rem;
  font-weight: 700;
}
.article-container .article .article-author .author .article-date {
  font-weight: 400;
  font-size: 0.8rem;
}
.article-container .article .article-author .article-stats {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}
.article-container .article .article-author .article-stats .like-count,
.article-container .article .article-author .article-stats .book,
.article-container .article .article-author .article-stats .share {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.article-container .article .article-author .article-stats .like-count span,
.article-container .article .article-author .article-stats .book span,
.article-container .article .article-author .article-stats .share span {
  opacity: 0.9;
  font-weight: 600;
  font-size: 0.85rem;
}
.article-container .article .article-author .article-stats .like-count button,
.article-container .article .article-author .article-stats .book button,
.article-container .article .article-author .article-stats .share button {
  padding: 0;
  background-color: transparent;
  width: 1.5rem;
  height: 1.5rem;
}
.article-container .article .article-author .article-stats .share-options {
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 12.5rem;
  right: -2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px -4px var(--shadow-portofollio);
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--shadow-portofollio);
}
.article-container .article .article-author .article-stats .share-options button,
.article-container .article .article-author .article-stats .share-options a {
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--txt-color);
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  font-size: 0.85rem;
  font-weight: 600;
}
.article-container .article .article-author .article-stats .share-options button img,
.article-container .article .article-author .article-stats .share-options a img {
  height: 1.2rem;
  width: 1.2rem;
}
.article-container .article .article-author .article-stats .share-options button:hover,
.article-container .article .article-author .article-stats .share-options a:hover {
  background-color: var(--bg-color-alert);
}
.article-container .article .article-author .article-stats .share-options button {
  border-bottom: 1px solid var(--shadow-portofollio);
  box-shadow: 0px 4px 10px -9px var(--shadow-portofollio);
}
.article-container .article .article-author .article-stats .share-options button img {
  height: 1.3rem;
  width: 1.3rem;
}
.article-container .article .article-thumbnail {
  width: 100%;
  padding: 1rem;
}
.article-container .article .article-thumbnail img {
  width: 100%;
  height: auto;
  aspect-ratio: 6/3;
  object-fit: contain;
}
.article-container .article .article-section {
  padding: 0 1rem 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 1.2rem;
  width: 100%;
}
.article-container .article .article-section h1,
.article-container .article .article-section h2,
.article-container .article .article-section h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.article-container .article .article-section h4,
.article-container .article .article-section h4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.article-container .article .article-section p,
.article-container .article .article-section ul,
.article-container .article .article-section ol {
  max-width: 100%;
  overflow-wrap: break-word;
  margin-bottom: 0.25rem;
}
.article-container .article .article-section p li,
.article-container .article .article-section ul li,
.article-container .article .article-section ol li {
  margin-bottom: 0.2rem;
}
.article-container .article .article-section .codeBlock {
  margin-left: -5%;
  width: 110%;
  padding: 0.5rem 1rem;
}
.article-container .article .article-section .codeBlock > * {
  width: 100%;
}
.article-container .article .article-section img {
  padding: 1rem;
  width: 100%;
  height: auto;
  aspect-ratio: 6/3;
  object-fit: contain;
}
.addArticle-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
.addArticle-container > button {
  align-self: center;
  width: 10rem;
  border-radius: 0.5rem;
}
.addArticle-container .article-field {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--shadow-portofollio);
}
.addArticle-container .article-field input,
.addArticle-container .article-field textarea {
  width: 100%;
}
.addArticle-container .article-field input[type="file"] {
  resize: none;
}
.addArticle-container .article-field input[type="datetime-local"] {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  border: none;
  color: var(--txt-color);
  background-color: var(--contact-bg-color);
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.4rem;
  resize: none;
  font-size: 1rem;
  width: auto;
}
.addArticle-container .article-field label {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.addArticle-container .article-field select {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  border: none;
  color: var(--txt-color);
  background-color: var(--contact-bg-color);
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.3rem;
  resize: vertical;
  font-size: 1rem;
}
.addArticle-container .article-field .article-text {
  flex-direction: column;
  align-items: flex-start;
}
.addArticle-container .article-field .editableDiv {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  border: none;
  color: var(--txt-color);
  background-color: var(--contact-bg-color);
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.8rem;
  font-size: 1rem;
  min-height: 100px;
}
.addArticle-container .article-field button {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.9rem;
  background-color: rgba(255, 0, 0, 0.82);
  border-radius: 0.5rem;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem !important;
  text-align: center;
}
.addArticle-container .article-field .add-list-item {
  position: relative;
  background-color: #0076fe;
}
.loginPage {
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginPage .loginForm {
  height: auto;
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -5%;
  padding: 0 !important;
}
.loginPage .loginForm > div:first-child {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.loginPage .loginForm img {
  align-self: center;
  width: 25%;
  margin: 0 0 1.5rem 0;
}
.loginPage .loginForm form {
  width: 100%;
  height: auto;
}
.loginPage .loginForm form button {
  width: 100%;
}
.loginPage .loginForm .login-input {
  width: 100%;
}
.loginPage .loginForm .login-input div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.loginPage .loginForm .login-input div label {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 6px;
}
.loginPage .loginForm > * {
  margin-bottom: 8px;
}
.loginPage .loginForm h2 {
  width: 550px;
  margin-bottom: 1rem;
  align-self: center;
}
.loginPage .loginForm h3 {
  font-size: 20px;
  width: 550px;
  margin-bottom: 1rem;
  align-self: center;
}
.loginPage .loginForm input,
.loginPage .loginForm button,
.loginPage .loginForm select {
  height: 2.4rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border-width: 2px;
}
.loginPage .loginForm input:focus-within {
  border-color: var(--acc-clr);
}
.loginPage .loginForm button {
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 1rem;
  background-color: var(--acc-clr);
}
.loginPage .loginForm .options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
}
.loginPage .loginForm .options > * {
  margin-bottom: 0.3rem;
  text-decoration: none;
}
.loginPage .loginForm .successMsg {
  margin-bottom: 5rem;
}
.loginPage .loginForm .successMsg p {
  font-weight: 600;
  width: 500px;
  align-self: center;
  text-align: left;
}
.loginPage .loginForm .success-opt > * {
  margin-bottom: 0.4rem;
}
.loginPage .loginForm .success-opt button {
  width: 80%;
  margin-bottom: 0.1rem;
  margin-top: 0.8rem;
}
.loginPage .loginForm .success-opt button > a {
  color: #fff;
  text-decoration: none;
}
.loginPage .loginForm .success-opt .buttonLike {
  width: 80%;
  height: 2.4rem;
  text-align: center;
  padding: 0.6rem;
  margin-top: 0.8rem;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--acc-clr);
}
.loadAny {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: auto;
}
@media (prefers-color-scheme: dark) {
  .mainStoreApp {
    --bg-color-store: #121318;
    --bg-nav-store: #1e1f25;
    --bg-app-color: #282a2f;
    --store-txt-color: #e2e2e9;
    --secondary-store-txt-color: #c5c6d0;
    --store-btn-color: #aec6ff;
  }
}
@media (prefers-color-scheme: light) {
  .mainStoreApp {
    --bg-color-store: #faf9ff;
    --bg-nav-store: #eeedf4;
    --bg-app-color: #e8e7ef;
    --store-txt-color: #000000;
    --secondary-store-txt-color: #44414a;
    --store-btn-color: #455e91;
  }
}
.mainStoreApp {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100dvh;
  position: relative;
  background-color: var(--bg-color-store);
  color: var(--store-txt-color);
}
@font-face {
  font-family: "Roboto";
  src: url("..//fonts/roboto-light-webfont.woff2") format("woff2"), url("..//fonts/roboto-light-webfont.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("..//fonts/roboto-medium-webfont.woff2") format("woff2"), url("..//fonts/roboto-medium-webfont.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("..//fonts/roboto-regular-webfont.woff2") format("woff2"), url("..//fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("..//fonts/Roboto-Bold.woff2") format("woff2"), url("..//fonts/Roboto-Bold.woff") format("woff");
  font-weight: 700;
}
.mainStoreApp button {
  font-family: "Roboto", sans-serif;
}
.mainStoreApp button :hover {
  background-color: transparent;
}
.mainStoreApp .NavStore {
  width: 100%;
  height: 4rem;
  position: fixed;
  top: calc(100dvh - 4rem);
  background-color: var(--bg-nav-store);
  z-index: 1;
  padding: 0;
}
.mainStoreApp .NavStore .navMenuStore {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-bottom: 2px;
}
.mainStoreApp .mainStore {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  overflow: auto;
  transition: left ease-in 0.35s;
}
.mainStoreApp .mainStore .header {
  position: fixed;
  width: 100%;
  height: 3rem;
  display: flex;
  padding: 1.75rem 0 1.75rem 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--txt-color);
  background-color: var(--bg-nav-store);
}
.mainStoreApp .mainStore .header h2 {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: 500;
}
.mainStoreApp .mainStore .appList {
  width: 100%;
  height: auto;
  padding: 0 1rem;
  padding-top: 5rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.mainStoreApp .mainStore .appList .appCard {
  background-color: var(--bg-app-color);
  width: 100%;
  border-radius: 0.5rem;
  max-height: 6rem;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  overflow: hidden;
  cursor: pointer;
}
.mainStoreApp .mainStore .appList .appCard .applogo {
  height: 4rem;
  aspect-ratio: 1;
  border-radius: 0.5rem;
}
.mainStoreApp .mainStore .appList .appCard .applogo img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 0.5rem;
}
.mainStoreApp .mainStore .appList .appCard .appDetails {
  width: 75%;
  max-height: 4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.mainStoreApp .mainStore .appList .appCard .appDetails h3 {
  color: var(--store-txt-color);
  font-size: 1.1rem;
  font-weight: 500;
}
.mainStoreApp .mainStore .appList .appCard .appDetails p {
  color: var(--secondary-store-txt-color);
  font-weight: 500;
  font-size: 0.75rem;
  line-clamp: 2;
  height: 1.8rem;
  white-space: normal;
  overflow: hidden;
  line-height: 0.9rem;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mainStoreApp .mainStore .appList .loadAny {
  position: relative;
  left: 0;
  align-items: center;
  transform: none;
  width: 60px;
  height: auto;
}
.mainStoreApp .mainStore .updateList {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  padding-top: 4rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  gap: 0.25rem;
}
.mainStoreApp .mainStore .updateList p:first-of-type {
  font-size: 1rem;
  font-weight: 500;
}
.mainStoreApp .mainStore .updateList p:last-of-type {
  font-size: 0.85rem;
}
.mainStoreApp .mainStore .updateList button {
  margin-top: 0.25rem;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  font-weight: 700;
  background-color: var(--store-btn-color);
  color: var(--bg-color-store);
}
.mainStoreApp .mainStore .updateList .loadAny {
  position: relative;
  width: 60px;
  height: auto;
  left: 0;
  transform: none;
}
.mainStoreApp .mainStore .settingsList {
  width: 100%;
  height: auto;
  padding: 0 1.5rem 0 3rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  gap: 1.35rem;
  border-bottom: 1px solid var(--shadow-portofollio);
}
.mainStoreApp .mainStore .settingsList .columnCheck {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  width: auto;
}
.mainStoreApp .mainStore .settingsList .columnCheck .switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}
.mainStoreApp .mainStore .settingsList .columnCheck .switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 1.75rem;
  height: 0.95rem;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-bottom: 0;
}
.mainStoreApp .mainStore .settingsList .columnCheck .switch-label .switch-button {
  content: "";
  position: absolute;
  top: -0.13rem;
  left: -3px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  transition: 0.3s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
.mainStoreApp .mainStore .settingsList .columnCheck .switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% + 3px);
  transform: translateX(-100%);
}
.mainStoreApp .mainStore .settingsList .columnCheck .switch-label:active .switch-button {
  width: 1rem;
}
.mainStoreApp .mainStore .settingsList > h4 {
  font-size: 0.85rem;
  color: var(--secondary-store-txt-color);
}
.mainStoreApp .mainStore .settingsList .toggleOption,
.mainStoreApp .mainStore .settingsList .togglePop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainStoreApp .mainStore .settingsList .toggleOption .toggDesc,
.mainStoreApp .mainStore .settingsList .togglePop .toggDesc {
  width: calc(100% - 1.75rem - 1rem);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.mainStoreApp .mainStore .settingsList .toggleOption .toggDesc p,
.mainStoreApp .mainStore .settingsList .togglePop .toggDesc p {
  font-size: 0.9rem;
  font-weight: 700;
}
.mainStoreApp .mainStore .settingsList .toggleOption .toggDesc span,
.mainStoreApp .mainStore .settingsList .togglePop .toggDesc span {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--secondary-store-txt-color);
}
.mainStoreApp .mainStore .settingsList .togglePop .toggDesc {
  width: 100%;
}
.mainStoreApp .mainStore .settingsList .disabled {
  opacity: 0.5;
}
.mainStoreApp .mainStore .settingsList .mementoTag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  gap: 0.2rem;
}
.mainStoreApp .mainStore .settingsList .mementoTag img,
.mainStoreApp .mainStore .settingsList .mementoTag svg {
  height: 0.8rem;
  width: auto;
}
.mainStoreApp .mainStore .settingsList:nth-child(3) {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.mainStoreApp .mainStore .settingsList:last-of-type {
  padding-top: 2rem;
  padding-bottom: 6rem;
}
.mainStoreApp .mainActive {
  transition: left ease-in 0.35s;
  left: -100%;
}
.mainStoreApp .appDetailsPage,
.mainStoreApp .openDetailsPage {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 100%;
  height: 100%;
  width: 100%;
  background-color: var(--bg-color-store);
  overflow-y: scroll;
  transition: left ease-in 0.35s;
}
.mainStoreApp .appDetailsPage p,
.mainStoreApp .openDetailsPage p,
.mainStoreApp .appDetailsPage span,
.mainStoreApp .openDetailsPage span {
  color: var(--secondary-store-txt-color);
}
.mainStoreApp .appDetailsPage .header,
.mainStoreApp .openDetailsPage .header {
  color: var(--store-txt-color);
  position: fixed;
  width: 100%;
  height: 3rem;
  display: flex;
  padding: 1.75rem 0 1.75rem 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 1rem;
  background-color: var(--bg-nav-store);
}
.mainStoreApp .appDetailsPage .header h2,
.mainStoreApp .openDetailsPage .header h2 {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: 500;
  color: var(--store-txt-color);
}
.mainStoreApp .appDetailsPage .header button,
.mainStoreApp .openDetailsPage .header button {
  background-color: transparent;
}
.mainStoreApp .appDetailsPage .header .backBtn,
.mainStoreApp .openDetailsPage .header .backBtn {
  position: absolute;
  top: 0.15rem;
  left: 0rem;
  width: 1.5rem;
  height: 1.5rem;
}
.mainStoreApp .appDetailsPage .header .backBtn button,
.mainStoreApp .openDetailsPage .header .backBtn button {
  width: 1.5rem;
  height: 1.5rem;
}
.mainStoreApp .appDetailsPage .header .backBtn button svg,
.mainStoreApp .openDetailsPage .header .backBtn button svg {
  fill: var(--store-txt-color);
  rotate: 180deg;
  width: 1.5rem;
  height: 1.5rem;
}
.mainStoreApp .appDetailsPage .header .settings,
.mainStoreApp .openDetailsPage .header .settings {
  position: absolute;
  top: 0.35rem;
  right: 2rem;
  width: 1.25rem;
  height: 1.25rem;
}
.mainStoreApp .appDetailsPage .header .settings button,
.mainStoreApp .openDetailsPage .header .settings button {
  width: 1.25rem;
  height: 1.25rem;
}
.mainStoreApp .appDetailsPage .header .settings button svg,
.mainStoreApp .openDetailsPage .header .settings button svg {
  fill: var(--store-txt-color);
  width: 1.25rem;
  height: 1.25rem;
}
.mainStoreApp .appDetailsPage .app,
.mainStoreApp .openDetailsPage .app {
  width: 100%;
  height: auto;
  padding: 0 1.5rem;
  padding-top: 5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.mainStoreApp .appDetailsPage .app .appHeader,
.mainStoreApp .openDetailsPage .app .appHeader {
  width: 100%;
  border-radius: 1rem;
  max-height: 10rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  overflow: hidden;
}
.mainStoreApp .appDetailsPage .app .appHeader .applogo,
.mainStoreApp .openDetailsPage .app .appHeader .applogo {
  width: 25%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.mainStoreApp .appDetailsPage .app .appHeader .applogo img,
.mainStoreApp .openDetailsPage .app .appHeader .applogo img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 1rem;
}
.mainStoreApp .appDetailsPage .app .appHeader .appDetails,
.mainStoreApp .openDetailsPage .app .appHeader .appDetails {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.mainStoreApp .appDetailsPage .app .appHeader .appDetails h2,
.mainStoreApp .openDetailsPage .app .appHeader .appDetails h2 {
  font-size: 1.2rem;
  font-weight: 500;
}
.mainStoreApp .appDetailsPage .app .appHeader .appDetails p,
.mainStoreApp .openDetailsPage .app .appHeader .appDetails p {
  color: var(--secondary-store-txt-color);
  font-weight: 400;
  font-size: 0.75rem;
}
.mainStoreApp .appDetailsPage .app .installButton,
.mainStoreApp .openDetailsPage .app .installButton {
  color: var(--bg-color-store);
  background-color: var(--store-btn-color);
  width: 100%;
  border-radius: 2rem;
  text-decoration: none;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
}
.mainStoreApp .appDetailsPage .app .screenshots,
.mainStoreApp .openDetailsPage .app .screenshots {
  height: auto;
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow: auto;
  padding-bottom: 1rem;
}
.mainStoreApp .appDetailsPage .app .screenshots img,
.mainStoreApp .openDetailsPage .app .screenshots img {
  width: 33.3%;
  aspect-ratio: 9/16;
  border-radius: 8px;
  border: 1px solid var(--shadow-portofollio);
}
.mainStoreApp .appDetailsPage .app .screenshots::-webkit-scrollbar-track,
.mainStoreApp .openDetailsPage .app .screenshots::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-portofollio);
  background-color: #15151500;
  border-radius: 10px;
}
.mainStoreApp .appDetailsPage .app .screenshots::-webkit-scrollbar,
.mainStoreApp .openDetailsPage .app .screenshots::-webkit-scrollbar {
  height: 5px;
  background-color: #15151500;
}
.mainStoreApp .appDetailsPage .app .screenshots::-webkit-scrollbar-thumb,
.mainStoreApp .openDetailsPage .app .screenshots::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #585858aa;
}
.mainStoreApp .appDetailsPage .app .aboutApp,
.mainStoreApp .openDetailsPage .app .aboutApp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-weight: 500;
}
.mainStoreApp .appDetailsPage .app .aboutApp .descHeader,
.mainStoreApp .openDetailsPage .app .aboutApp .descHeader {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainStoreApp .appDetailsPage .app .aboutApp .descHeader > button,
.mainStoreApp .openDetailsPage .app .aboutApp .descHeader > button {
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainStoreApp .appDetailsPage .app .aboutApp .descHeader > button :hover,
.mainStoreApp .openDetailsPage .app .aboutApp .descHeader > button :hover {
  background-color: transparent;
}
.mainStoreApp .appDetailsPage .app .aboutApp .descHeader svg,
.mainStoreApp .openDetailsPage .app .aboutApp .descHeader svg {
  fill: var(--store-txt-color);
  height: 1.5rem;
  width: 1.5rem;
}
.mainStoreApp .appDetailsPage .app .aboutApp .descHeader h3,
.mainStoreApp .openDetailsPage .app .aboutApp .descHeader h3 {
  font-weight: 500;
  font-size: 1.1rem;
}
.mainStoreApp .appDetailsPage .app .aboutApp p,
.mainStoreApp .openDetailsPage .app .aboutApp p {
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 5px;
  height: 3.75rem;
  white-space: normal;
  overflow: hidden;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* Show ellipsis (...) for truncated text */
}
.mainStoreApp .appDetailsPage .app .aboutApp .infoList,
.mainStoreApp .openDetailsPage .app .aboutApp .infoList {
  margin-top: 0.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.mainStoreApp .appDetailsPage .app .aboutApp .infoList > div,
.mainStoreApp .openDetailsPage .app .aboutApp .infoList > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
}
.mainStoreApp .appDetailsPage .app .aboutApp .infoList > div span:first-of-type,
.mainStoreApp .openDetailsPage .app .aboutApp .infoList > div span:first-of-type {
  font-weight: 500;
}
.mainStoreApp .appDetailsPage .app .aboutApp .infoList > div span:last-of-type,
.mainStoreApp .openDetailsPage .app .aboutApp .infoList > div span:last-of-type {
  font-weight: 400;
}
.mainStoreApp .openDetailsPage .header {
  padding-left: 3rem;
  justify-content: flex-start;
}
.mainStoreApp .openDetailsPage .app p {
  font-size: 0.9rem;
  font-weight: 400;
}
.mainStoreApp .appDetailsPage::-webkit-scrollbar-track,
.mainStoreApp .mainStore::-webkit-scrollbar-track,
.mainStoreApp .openDetailsPage::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--shadow-portofollio);
  background-color: #15151500;
  border-radius: 10px;
}
.mainStoreApp .appDetailsPage::-webkit-scrollbar,
.mainStoreApp .openDetailsPage::-webkit-scrollbar,
.mainStoreApp .mainStore::-webkit-scrollbar {
  width: 5px;
  background-color: #15151500;
}
.mainStoreApp .appDetailsPage::-webkit-scrollbar-thumb,
.mainStoreApp .openDetailsPage::-webkit-scrollbar-thumb,
.mainStoreApp .mainStore::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #585858aa;
}
.mainStoreApp .active {
  left: 0%;
  transition: all ease-in 0.3s;
}
.mainStoreApp .activeDetails {
  left: -100%;
  transition: all ease-in 0.3s;
}
.popup-overlay-store {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* Semi-transparent background to gray out the rest of the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  /* Ensure it appears above other content */
}
.popup-overlay-store .popup-content {
  color: var(--store-txt-color);
  background-color: var(--bg-nav-store);
  padding: 1.2rem;
  border-radius: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  min-width: 220px;
  min-height: 100px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
}
.popup-overlay-store .popup-content .popup-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.popup-overlay-store .popup-content .popup-header .close-popup-button {
  cursor: pointer;
}
.popup-overlay-store .popup-content .popup-header h2 {
  margin: 0;
  font-size: 1.2rem;
}
.popup-overlay-store .popup-content .popup-header .close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
}
.popup-overlay-store .popup-body {
  color: var(--store-txt-color);
  margin-bottom: 1rem;
}
.popup-overlay-store .popup-body span {
  height: auto;
}
.popup-overlay-store .popup-body input {
  margin-top: 1rem;
  width: 100%;
}
.popup-overlay-store .popup-body label {
  display: block;
  margin-top: 0.6rem;
}
.popup-overlay-store .popup-body label input {
  margin-top: 0.3rem;
  width: 100%;
}
.popup-overlay-store .popup-body textarea {
  width: 100%;
  margin-top: 0.5rem;
  max-height: 500px;
  min-height: 200px;
}
.popup-overlay-store .popup-body select {
  margin: 0.5rem 0;
  width: 100%;
  height: 2.5rem;
}
.popup-overlay-store .popup-body .intervals {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-size: 1rem;
}
.popup-overlay-store .popup-body .intervals label {
  width: 100%;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}
.popup-overlay-store .popup-body .intervals label input {
  width: 1rem;
  height: 1rem;
  margin: 0;
}
.popup-overlay-store .popup-body .intervals label input[type="radio"] {
  width: 1rem;
  height: 1rem;
  accent-color: var(--store-btn-color);
}
.popup-overlay-store .popup-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  height: auto;
}
.popup-overlay-store .popup-footer button {
  margin-left: 10px;
  padding: 0.25rem;
  font-size: 1rem;
  color: var(--store-txt-color);
  background-color: transparent;
  border-radius: 8px;
}
.popup-overlay-store .popup-footer img {
  margin-top: 1rem;
  width: 3rem;
  height: auto;
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}
@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1640px) {
  .calendly-overlay .calendly-popup {
    height: 1200px;
  }
  .main > *,
  nav,
  .footer {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .about-section .about-img1 {
    left: 10%;
  }
}
@media screen and (max-width: 1440px) {
  .our-team .team-member h3 {
    line-height: 1.2rem;
    height: 2.5rem;
  }
  .schedule .promo {
    min-width: 420px;
    max-height: 12rem;
  }
  .portofolio-info {
    width: 100%;
    min-width: 900px;
  }
  .hero-section .hero-img2 {
    scale: 1;
  }
}
@media screen and (max-width: 1280px) {
  .main > *,
  nav,
  .footer {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  .hero-section div:first-child {
    margin-top: -1rem;
  }
  .hero-section .hero-img2 {
    right: 0rem;
  }
  .about-section .about-img1 {
    left: 6%;
    scale: 0.9;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 15px;
  }
  nav,
  .footer {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
  .hero-section div:first-child {
    margin-top: -1rem;
  }
  .hero-section .hero-img1 {
    right: 0px;
  }
  .hero-section .hero-img2 {
    top: -3.5rem;
    scale: 1;
    right: 1rem;
  }
  .about-section .about-img1 {
    left: 3%;
    scale: 0.8;
  }
  .portofolio-section {
    padding: 0px;
    padding-top: 2rem;
    padding-bottom: 6rem;
    margin: 0 auto;
  }
  .portofolio-info {
    width: 100%;
    min-width: 900px;
    align-self: center;
  }
  .portofolio-info .info-section {
    flex-basis: 45%;
    padding: 1rem 1rem;
  }
  .faq-section {
    width: 100%;
    position: relative;
  }
  .schedule {
    height: auto;
  }
  .schedule .schedule-button .calendlyButton {
    padding: 20px 100px;
  }
  .schedule .promo {
    min-width: 380px;
    align-self: center;
  }
  .headline-contact {
    text-align: center;
  }
  .contact {
    flex-direction: column;
    align-items: center;
  }
  .contact-form {
    order: -1;
    max-width: 640px;
  }
  .contact-info {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
  }
  .contact-info a {
    width: inherit !important;
  }
  .contact-info .info-contc {
    flex-basis: 40%;
  }
  .contact-info .social-info {
    flex-basis: 40%;
  }
  .contact-info .social-info > a:nth-child(2),
  .contact-info .social-info a:nth-child(3) {
    min-width: unset !important;
  }
  .contact-info #map {
    width: 200% !important;
  }
  .contact-info .hidden {
    display: block;
  }
  .dots {
    display: none;
  }
  .article-list-container .article-list {
    margin-left: 4%;
  }
  .article-list-container .article-list-options {
    margin-right: 4%;
  }
  .article-container .article,
  .article-container .headline-article {
    width: 75%;
  }
}
@media screen and (max-width: 900px) {
  html {
    font-size: 14px;
  }
  nav,
  .footer {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
  .hero-section div:first-child {
    margin-top: 0px;
  }
  .hero-section img {
    right: 0px !important;
  }
  .hero-section .hero-img2 {
    scale: 0.9;
    right: 1rem !important;
  }
  .about-section .about-img1 {
    top: 0;
    left: -5%;
  }
  .rdr-right {
    flex-basis: 40%;
  }
  .rdr-right .cta-Div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    left: unset !important;
    bottom: unset !important;
    padding-bottom: 1rem;
  }
  .rdr-left {
    flex-basis: 60%;
  }
  .our-team .team {
    flex-wrap: wrap;
    width: 100%;
    margin: 0px;
  }
  .our-team .team-member {
    flex-basis: 45%;
  }
  .our-team .team-member .profilePhoto {
    width: 80%;
  }
  .our-team .team-member h3 {
    height: 1.2rem;
  }
  .portofolio-info {
    flex-direction: column;
    align-items: center;
    min-width: unset;
  }
  .portofolio-info .phone-section {
    width: 40%;
    min-width: 280px !important;
  }
  .portofolio-info .phone-section .phone-out {
    height: auto;
    width: 100%;
    aspect-ratio: 9/19;
  }
  .portofolio-info .info-section {
    flex-basis: unset;
    width: 100%;
  }
  .faq-section {
    width: 100%;
    justify-content: center;
  }
  .faq-section .faq-questions {
    width: 90%;
    flex-basis: unset;
  }
  .schedule {
    height: auto;
  }
  .schedule .schedule-button {
    flex-grow: 1;
    max-height: unset;
    margin-left: auto;
  }
  .schedule .promo {
    height: auto;
    max-height: none;
  }
  .schedule .promo .talk-points {
    padding-top: 3rem;
  }
  .schedule .promo .price {
    top: 3rem;
  }
  .article-list-container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .article-list-container .article-list-options {
    order: 0;
    width: 100%;
    padding: 0.2rem 0 0.5rem 0;
    display: flex;
    align-items: center;
    margin: 0;
    height: auto;
    border-left: none;
    border-bottom: 1px solid var(--shadow-portofollio);
    position: relative;
    overflow: hidden;
  }
  .article-list-container .article-list-options h3 {
    display: none;
  }
  .article-list-container .article-list-options .topics {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 0.5rem;
    position: relative;
    mask-image: linear-gradient(to right, transparent 1%, black 3%, black 98%, transparent 100%);
  }
  .article-list-container .article-list-options .topics::before,
  .article-list-container .article-list-options .topics::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* Adjust as needed for the size of the gradient */
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    pointer-events: none;
    /* Ensures the overlay does not interfere with content */
  }
  .article-list-container .article-list-options .topics::after {
    right: 0;
    left: auto;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  }
  .article-list-container .article-list-options .topics::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--shadow-portofollio);
    background-color: #15151500;
    border-radius: 10px;
  }
  .article-list-container .article-list-options .topics::-webkit-scrollbar {
    height: 5px;
    background-color: #15151500;
  }
  .article-list-container .article-list-options .topics::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #585858aa;
  }
  .article-list-container .article-list {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-height: 450px) {
  .hero-section {
    min-height: 600px;
  }
  .hero-section .hero-img2 {
    scale: 1.2;
    right: 3rem !important;
    min-height: 210px !important;
  }
}
@media screen and (min-height: 1441px) {
  .hero-section {
    height: 100dvh;
    flex-direction: column !important;
  }
  .hero-section > .cta-Div {
    display: flex;
  }
  .hero-section div:first-child {
    margin-top: -3rem;
    height: 360px;
    width: 100%;
    max-width: none;
  }
  .hero-section div:first-child p {
    width: 400px;
  }
  .hero-section div:first-child .cta-Div {
    display: none;
    align-self: flex-end;
  }
  .hero-section .images {
    width: 100%;
    height: auto;
    right: unset;
    max-width: unset;
    flex-basis: unset;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: -8rem;
  }
  .hero-section .hero-img1 {
    right: -30% !important;
    rotate: 15deg;
  }
  .hero-section .hero-img2 {
    top: -15rem;
    right: -5% !important;
    width: 100%;
    height: auto;
    scale: 1.2;
  }
}
@media screen and (max-width: 768px) {
  .hero-section {
    height: auto;
    flex-direction: column !important;
  }
  .hero-section > .cta-Div {
    display: flex;
  }
  .hero-section div:first-child {
    margin-top: -3rem;
    height: 360px;
    width: 100%;
    max-width: none;
  }
  .hero-section div:first-child p {
    width: 400px;
  }
  .hero-section div:first-child .cta-Div {
    display: none;
    align-self: flex-end;
  }
  .hero-section .images {
    width: 100%;
    height: auto;
    right: unset;
    max-width: unset;
    flex-basis: unset;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: -8rem;
  }
  .hero-section .hero-img1 {
    right: -30% !important;
    rotate: 15deg;
  }
  .hero-section .hero-img2 {
    top: -15rem;
    right: -15% !important;
    width: 100%;
    height: auto;
    scale: 0.9;
  }
  .about-section .about-img1 {
    margin-top: 10%;
    width: 50%;
    left: 0;
    scale: 1;
  }
  .redirect-short {
    height: auto;
    padding: 3rem 0px;
  }
  .redirect-banner {
    height: calc(100% - 4rem);
    flex-direction: column;
  }
  .redirect-banner .rdr-left {
    flex-basis: 70%;
  }
  .redirect-banner .rdr-right {
    width: 100%;
    padding: 2rem 2rem;
  }
  .redirect-banner .rdr-right .cta-Div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: unset;
  }
  .navMenu {
    position: absolute;
    z-index: 5;
    top: 0px;
    right: 0px;
    padding-top: 60px;
    height: 100dvh;
    width: 200px;
    display: flex;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--nav-bg-color);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  .flex {
    display: flex;
  }
  .none {
    display: none;
  }
  .blur::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .schedule {
    width: 100%;
    flex-direction: column;
  }
  .schedule > * {
    top: unset;
    right: unset;
  }
  .schedule .promo {
    margin: 3rem 0px;
    width: 80%;
    min-width: 400px;
  }
  .schedule .schedule-button {
    width: 100%;
    margin: 3rem auto;
  }
  .schedule .schedule-button p {
    margin-bottom: 3rem;
  }
  .schedule .schedule-button .calendlyButton {
    align-self: center;
  }
  .contact-form {
    min-width: auto;
  }
  .contact-info #map {
    width: 140% !important;
  }
  .article-container .article,
  .article-container .headline-article {
    width: 85%;
  }
}
@media screen and (max-width: 600px) {
  .hero-section {
    padding-bottom: 50px;
  }
  .hero-section .hero-img2 {
    top: -12rem;
  }
  .about-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  .about-section div:first-child {
    max-width: unset;
    width: 100%;
  }
  .about-section .about-img1 {
    position: relative;
    width: 100%;
  }
  .portofolio-section {
    height: auto;
  }
  .portofolio-info {
    width: 100%;
    position: relative !important;
    top: unset !important;
    height: auto;
    max-height: unset;
  }
  .portofolio-info .cta-Div {
    justify-content: center;
    flex-wrap: wrap;
  }
  .portofolio-info .cta-Div a:first-child {
    margin: 1rem 0px;
  }
  .white-section {
    display: none;
  }
  .faq-section {
    height: auto;
  }
  .faq-section .faq-questions {
    width: 100%;
  }
  .contact-info {
    width: 100%;
    margin-right: 0px;
    flex-direction: column;
    align-items: center;
  }
  .contact-info a {
    width: 80% !important;
  }
  .contact-info .info-contc,
  .contact-info .social-info {
    width: 100%;
    flex-basis: unset;
    text-align: center;
  }
  .contact-info .info-contc > *,
  .contact-info .social-info > * {
    margin: 0 auto;
  }
  .contact-info #map {
    width: 100% !important;
  }
  .contact-info h3 {
    margin-bottom: 0.5rem !important;
  }
  .rdr-right .cta-Div {
    flex-direction: column !important;
  }
  .rdr-right .cta-Div a {
    width: 60%;
    min-width: 13rem;
  }
  .rdr-right .cta-Div a:first-child {
    margin-bottom: 0.5rem;
  }
  .our-team .headline {
    max-width: 100%;
  }
  .our-team .headline p {
    width: 100%;
  }
  .our-team .team {
    width: 100%;
  }
  .our-team .team-member h3 {
    height: 2.5rem;
  }
  .schedule .promo {
    min-width: 300px;
    width: 100%;
  }
  .article-container .article,
  .article-container .headline-article {
    width: 92%;
  }
}
@media screen and (max-width: 450px) {
  html {
    font-size: 12px;
  }
  .hero-section div:first-child {
    margin-top: -5rem;
  }
  .hero-section div:first-child * {
    width: 100%;
  }
  .hero-section div:first-child p {
    width: 90%;
  }
  .hero-section .hero-img2 {
    top: -8rem;
  }
  .hero-section .cta-Div {
    padding-top: 4rem;
    flex-direction: column;
    padding-bottom: 4rem;
  }
  .hero-section .cta-Div a {
    margin-bottom: 1rem;
  }
  .about-short .cta-about {
    width: 100%;
    max-width: none;
    align-self: center;
  }
  .about-short .cta-about a {
    width: 100%;
    max-width: none;
    margin-right: 0px;
  }
  .our-team .team-member {
    flex-basis: 100%;
  }
  .our-team .team-member > img {
    max-width: 240px;
  }
  .our-team .team-member h3 {
    height: 1.2rem;
  }
  .contact-form {
    width: 100%;
  }
  .contact-form .pearson-info {
    flex-direction: column;
  }
  .contact-form .pearson-info div {
    width: 100%;
  }
  .article-list-container .article-list {
    padding: 1rem;
  }
  .article-list-container .article-list .article .article-section {
    flex-direction: column;
  }
  .article-list-container .article-list .article .article-section .article-content,
  .article-list-container .article-list .article .article-section .article-thumbnail {
    width: 100%;
  }
  .article-list-container .article-list .article .article-section .article-content img,
  .article-list-container .article-list .article .article-section .article-thumbnail img {
    aspect-ratio: 8/3;
  }
  .article-container .article,
  .article-container .headline-article {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .schedule .schedule-button {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .contact-info a {
    width: 100% !important;
    min-width: unset;
  }
  #map {
    max-width: 100%;
  }
}
